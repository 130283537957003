export const filterByLocale = data => {
  const locales = localStorage.getItem('gatsby-intl-language');
  let filteredLocale;

  if (['en', 'fr'].includes(locales)) {
    filteredLocale = locales === 'en' ? 'en-US' : locales;
  } else {
    filteredLocale = 'en-US';
  }

  let filteredData = data.filter(
    edge => edge.node.node_locale === filteredLocale,
  );

  return filteredData;
};
