import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { graphql, useStaticQuery } from 'gatsby';
import { filterByLocale } from 'utils/filterDataByLocale';

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: 420,
  },
}));

const Story = props => {
  const data = useStaticQuery(graphql`
    query {
      pages: allContentfulPages(filter: { slug: { eq: "digital-marketing" } }) {
        edges {
          node {
            node_locale
            title
            id
            firstButton
            body {
              body
            }
            imageOne {
              file {
                url
              }
              title
            }
          }
        }
      }
    }
  `);
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const filteredData = filterByLocale(data.pages.edges);

  return (
    <div className={className} {...rest}>
      {filteredData.map((edge, index) => {
        return (
          <div key={index}>
            <Grid
              container
              justify="space-between"
              spacing={isMd ? 4 : 2}
              direction={isMd ? 'row' : 'column-reverse'}
            >
              <Grid
                item
                container
                justify={isMd ? 'flex-end' : 'flex-start'}
                alignItems="left"
                xs={12}
                md={4}
                data-aos={'fade-up'}
              >
                <Image
                  width="400px"
                  src={edge.node.imageOne.file.url}
                  alt={edge.node.imageOne.title}
                  className={classes.image}
                />
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                justify="flex-start"
                xs={12}
                md={7}
                data-aos={'fade-up'}
              >
                <div>
                  <SectionHeader
                    title={edge.node.title}
                    subtitle={edge.node.body.body}
                    align="left"
                    disableGutter
                    ctaGroup={[
                      <Button variant="contained" color="primary" size="large">
                        {edge.node.firstButton}
                      </Button>,
                    ]}
                    subtitleProps={{
                      color: 'textPrimary',
                      variant: 'body1',
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        );
      })}
    </div>
  );
};

Story.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Story;
